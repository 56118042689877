import { destructure } from "@solid-primitives/destructure";
import { isEmpty } from "lodash-es";
import type { Side } from "~/types/Side";
import { APP_STATE, BROWSING_STATE, MODEL_GAMES_STATE, useMode } from "~/utils/app_state";
import type { ChessboardInterface } from "~/utils/chessboard_interface";
import { clsx } from "~/utils/classes";
import { isChessmadra } from "~/utils/env";
import { getLichessLink } from "~/utils/lichess";
import { c, stylex } from "~/utils/styles";
import { trackEvent } from "~/utils/trackEvent";
import { FadeInOut } from "./FadeInOut";
import { InAppBrowserLink } from "./InAppBrowserLink";

export const AnalyzeOnLichessButton = (props: {
	short?: boolean;
	chessboard: ChessboardInterface;
}) => {
	const mode = useMode();
	const sidebarSide = () => BROWSING_STATE().activeSide;
	const modelGameSide = () => MODEL_GAMES_STATE().activeGame?.result;
	const [currentLine, activeSide] = destructure(() => {
		if (mode() === "review") {
			return [props.chessboard.getMoveLog(), APP_STATE().repertoireState.reviewState.reviewSide];
		}
		const side: Side | undefined | null =
			mode() === "model_games" ? modelGameSide() : sidebarSide();
		return [props.chessboard.getMoveLog(), side ?? props.chessboard.getTurn()];
	});
	const href = () => {
		const side = activeSide();
		const ply = currentLine().length;
		const associatedGame = props.chessboard.get((cb) => cb.associatedGame);
		const line = currentLine();
		if (associatedGame?.link && associatedGame?.source === "Lichess") {
			return `${associatedGame.link}#${ply}`;
		}
		return getLichessLink(line, { side: side, ply: ply });
	};
	return (
		<FadeInOut
			style={stylex(c.row)}
			class={clsx("shrink-0 ")}
			open={
				(!isEmpty(currentLine()) &&
					(mode() === "browse" ||
						mode() === "review" ||
						mode() === "build" ||
						mode() === "model_games" ||
						mode() === "openings_report")) ||
				isChessmadra
			}
		>
			<InAppBrowserLink
				style={stylex()}
				class={clsx(
					"text-sm text-tertiary &hover:text-primary  -my-2 shrink-0 py-2 font-medium transition-colors",
				)}
				href={href()}
				onClick={() => {
					trackEvent("chessboard.analyze_on_lichess", {
						side: activeSide(),
						mode: mode(),
					});
					return false;
				}}
			>
				<p>
					{props.short ? "Analyze" : "Analyze on Lichess"}
					<i class="fa fa-up-right-from-square pl-2 " />
				</p>
			</InAppBrowserLink>
		</FadeInOut>
	);
};
