import type { Move } from "@lubert/chess.ts";
import type { Square } from "@lubert/chess.ts/dist/types";
import { formatISO } from "date-fns";
import { drop, last, map, noop, take } from "lodash-es";
import toast from "solid-toast";
import { ModelGameViewer } from "~/components/ModelGameViewer";
import { animateSidebar } from "~/components/SidebarContainer";
import type { ClientChessGame } from "~/rspc";
import { ChessGame } from "~/types/ChessGame";
import { MoveRating } from "~/types/MoveRating";
import { Side } from "~/types/Side";
import { type AppState, UI, quick } from "./app_state";
import { devAssert } from "./assert";
import { genEpd } from "./chess";
import { createChessboardInterface } from "./chessboard_interface";
import client from "./client";
import { type MoveHint, getHintsForMove } from "./hints";
import { getMoveRating } from "./move_inaccuracy";
import type { RepertoireState } from "./repertoire_state";
import { rspcClient } from "./rspc_client";
import { sleep } from "./sleep";
import { SOUNDS, playSound } from "./sounds";
import { Stockfish } from "./stockfish";
import { dailyTaskFinished } from "./streaks";

export type ModelGamesState = ReturnType<typeof getInitialModelGamesState>;

type Stack = [ModelGamesState, RepertoireState, AppState];

type FetchModelGameOptions = { epd?: string; line?: string[]; side?: Side };

export type ModelGameHintStage = "hint" | "arrow";

export const getInitialModelGamesState = () => {
	const set = <T,>(fn: (stack: Stack) => T, _id?: string) => {
		return quick((s) => {
			return fn([s.repertoireState.modelGamesState, s.repertoireState, s]);
		});
	};
	const initialState = {
		dailyGame: undefined as ChessGame | null | undefined,
		hints: [] as MoveHint[],
		validAlternateMovesGuessed: new Set() as Set<string>,
		nextMove: undefined as Move | undefined,
		history: [] as ChessGame[],
		hintStage: null as ModelGameHintStage | null,
		activeGame: undefined as ChessGame | null | undefined,
		chessboard: createChessboardInterface()[1],
		previewing: false,
		lastFetchOptions: undefined as FetchModelGameOptions | undefined,
		guessedPlys: {
			correct: new Set() as Set<number>,
			mistakes: new Set() as Set<number>,
			blunders: new Set() as Set<number>,
			inaccuracies: new Set() as Set<number>,
		},
		resetHints: () => {
			set(([s]) => {
				s.validAlternateMovesGuessed.clear();
				s.hints = [];
				s.hintStage = null;
				if (s.activeGame) {
					const nextMoveSan = s.activeGame.sans[s.ply];
					const move = s.chessboard.get((s) => s.position.validateMoves([nextMoveSan]))!;
					if (move) {
						s.hints = getHintsForMove(move[0]);
					}
				}
			});
		},
		fetchAndReviewNewGame: (options: FetchModelGameOptions) => {
			UI()
				.loadUntil(
					rspcClient.query([
						"openings.fetchModelGame",
						{
							epd: options.epd ?? null,
							date: formatISO(new Date()),
							line: options.line ?? null,
							side: options.side ?? null,
						},
					]),
				)
				.then((data) => {
					const game = data.game;
					if (!game) {
						toast.error("No model game found");
						return;
					}
					set(([s, _rs, _app_state]) => {
						s.reviewGame(
							ChessGame.fromDto(game!),
							{
								startPly: options.line?.length ?? 0,
							},
							options,
						);
					});
				});
		},
		ply: 0 as number,
		setActiveGame: (game: ChessGame) => {
			set(([s]) => {
				s.activeGame = game;
				s.ply = 0;
				s.chessboard.resetPosition();
				s.previewing = false;
				s.chessboard.setPerspective(game.result!);
				s.guessedPlys = {
					correct: new Set(),
					mistakes: new Set(),
					blunders: new Set(),
					inaccuracies: new Set(),
				};
			});
		},
		skipToEnd: () => {
			set(([s]) => {
				if (!s.activeGame) {
					return;
				}
				drop(s.activeGame.sans, s.ply).forEach((move) => {
					s.chessboard.makeMove(move, { animate: false, sound: "none" });
				});
				s.ply = s.chessboard.getPly();
				s.resetHints();
				s.setNextMove();
			});
		},
		backOne: () => {
			set(([s]) => {
				s.chessboard.backOne({ clear: true });
				s.ply = s.chessboard.getPly();
				s.chessboard.highlightSquare(null);
				s.resetHints();
				s.setNextMove();
			});
		},
		showNextHint: () => {
			set(([s]) => {
				if (s.hintStage === "hint") {
					s.hintStage = "arrow";
					return;
				}
				const hint = last(s.hints);
				if (hint && "piece" in hint) {
					s.hintStage = "hint";
					s.chessboard.highlightSquare(hint.square);
				}
			});
		},
		setNextMove: () =>
			set(([s]) => {
				if (!s.activeGame) {
					s.nextMove = undefined;
					return;
				}
				const ply = s.ply;
				const nextMove = s.activeGame?.sans[ply];
				if (!nextMove) {
					s.nextMove = undefined;
					return;
				}
				let pos = s.chessboard.get((s) => s.position);
				let moveObject = pos.validateMoves([nextMove]);
				if (moveObject) {
					s.nextMove = moveObject[0];
				}
			}),
		playNextMove: () => {
			set(([s]) => {
				if (!s.activeGame) {
					return;
				}
				const ply = s.ply;
				const nextMove = s.activeGame.sans[ply];
				if (!nextMove) {
					return;
				}
				if (ply === 0) {
					dailyTaskFinished();
				}
				// if (ply === 0) {
				// 	animateSidebar("right");
				// }
				s.chessboard.makeMove(nextMove, { animate: true, sound: "move" });
				s.ply = s.chessboard.getPly();
				s.chessboard.highlightSquare(null);
				s.resetHints();
				s.setNextMove();
			});
		},
		reviewGame: (
			game: ChessGame,
			options?: { startFinished?: boolean; startPly?: number },
			fetchOptions?: FetchModelGameOptions,
		) => {
			set(([s, rs, _app_state]) => {
				s.lastFetchOptions = fetchOptions;
				s.setActiveGame(game);
				s.totalGuesses = 0;
				if (options?.startFinished) {
					s.chessboard.playLine(game.sans);
				}
				s.chessboard.set((cb) => {
					cb.associatedGame = game;
				});
				UI().ensureView(ModelGameViewer, { props: { game } });
				rs.fetchNeededAnnotations();
				s.resetHints();
				s.setNextMove();
				let startPly = options?.startPly ?? 0;
				if (startPly === 0 && game.result === "black") {
					startPly = 1;
				}
				if (startPly) {
					set(([s]) => {
						take(game.sans, startPly).forEach((move) => {
							s.chessboard.makeMove(move, { animate: false, sound: "none" });
						});
						s.ply = s.chessboard.getPly();
					});
				}
			});
		},
		markGameAsReviewed: (game: ChessGame) => {
			set(([s, _rs, _app_state]) => {
				[s.dailyGame, s.activeGame].forEach((g) => {
					if (g && g.id === game.id) {
						g.reviewed = true;
					}
				});
			});
			return client
				.post("/api/v1/model_game_reviewed", {
					gameId: game.id,
				})
				.then(() => {
					set(([s, _rs, _app_state]) => {
						s.fetchModelGameHistory();
					});
				});
		},
		guessedMove: (move: Move) => {
			return set(([s]) => {
				const activeGame = s.activeGame;
				if (!activeGame) {
					return false;
				}
				const ply = s.ply;
				const nextMove = activeGame.sans[ply];
				const correct = nextMove === move.san;
				if (correct && !s.hasGuessedPly(ply)) {
					s.guessPly(ply, "correct");
				}
				return correct;
			});
		},
		totalGuesses: 0,
		guessPly: (ply: number, result: "correct" | "inaccuracies" | "mistakes" | "blunders") => {
			set(([s]) => {
				if (s.hasGuessedPly(ply)) {
					return;
				}
				s.guessedPlys[result].add(ply);
				s.totalGuesses = map(s.guessedPlys, (guesses) => guesses.size).reduce((a, b) => a + b, 0);
			});
		},
		hasGuessedPly: (ply: number) => {
			return set(([s]) => {
				return (
					s.guessedPlys.correct.has(ply) ||
					s.guessedPlys.inaccuracies.has(ply) ||
					s.guessedPlys.mistakes.has(ply) ||
					s.guessedPlys.blunders.has(ply)
				);
			});
		},
		fetchModelGameHistory: () => {
			return set(([_s, _rs, app_state]) => {
				if (!app_state.userState.flagEnabled("model_games")) {
					return Promise.resolve();
				}
				return client.post("/api/v1/model_game_history", {}).then(
					({
						data,
					}: {
						data: {
							games: ClientChessGame[];
						};
					}) => {
						set(([s]) => {
							s.history = map(data.games, (g) => ChessGame.fromDto(g));
						});
					},
				);
			});
		},
		fetchDailyModelGame: (options?: { forceNewGame?: boolean }) => {
			return set(([_s, _rs, app_state]) => {
				if (!app_state.userState.flagEnabled("model_games")) {
					return Promise.resolve();
				}
				return client
					.post("/api/v1/daily_model_game", {
						date: formatISO(new Date()),
						forceNewGame: options?.forceNewGame ?? false,
					})
					.then(
						({
							data,
						}: {
							data: {
								game?: ClientChessGame;
							};
						}) => {
							set(([s]) => {
								if (!data.game) {
									s.dailyGame = null;
								} else {
									s.dailyGame = ChessGame.fromDto(data.game);
								}
							});
						},
					);
			});
		},
	};
	initialState.chessboard.set((c) => {
		c.delegate = {
			completedMoveAnimation: noop,
			onPositionUpdated: () => {
				set(([s]) => {
					if (s.chessboard.getCurrentEpd() === last(s.activeGame?.epds)) {
						animateSidebar("right");
						s.markGameAsReviewed(s.activeGame!);
					}
				});
			},

			madeManualMove: () => {
				set(([_s, _rs]) => {});
			},
			onBack: () => {
				set(([_s]) => {
					// todo
				});
			},
			onReset: () => {
				set(([_s]) => {
					// todo
				});
			},
			onPositionUpdate: () => {
				set(([s, _rs]) => {
					s.resetHints();
					s.setNextMove();
					s.chessboard.highlightSquare(null);
				});
			},
			shouldMakeMove: (move: Move) =>
				set(([s]) => {
					const activeGame = s.activeGame;
					if (!activeGame) {
						return false;
					}
					if (s.ply === 0) {
						dailyTaskFinished();
					}
					const currentEval = activeGame.evals[s.ply];
					const correct = s.guessedMove(move);
					s.chessboard.showMoveFeedback(
						{
							square: move.to as Square,
							result: correct ? "correct" : "thinking",
							progress: correct ? undefined : 0.05,
						},
						() => {
							// sorta redundant, won't be called if thinking
							if (correct) {
								if (Side.fromColor(move.color) === activeGame.result) {
									s.playNextMove();
								}
							}
						},
					);
					if (correct) {
						s.ply = s.chessboard.getPly() + 1;
						s.hintStage = null;
						return true;
					}
					const pos = s.chessboard.get((c) => c.position).clone();
					let _v = pos.move(move);
					const epd = genEpd(pos);

					s.previewing = true;
					Stockfish.evalPos({
						epd,
						cb: (_r) => {
							const info = Stockfish.lastInfo;
							if (!info?.nodes) {
								return;
							}
							set(([s]) => {
								s.chessboard.set((c) => {
									c.moveFeedback.progress = Math.min(info!.nodes! / (Stockfish.nodeGoal * 0.5), 1);
								});
							});
						},
						nodes: 25_000,
					})
						.then((stockfishEval) => {
							let moveRating = getMoveRating({
								before: currentEval,
								after: stockfishEval,
								side: s.activeGame!.result!,
								includeInaccuracy: true,
							});
							// Stockfish.evalPos({
							// 	epd,
							// 	cb: (_r) => {},
							// 	nodes: 100_000,
							// }).then((stockfishEval) => {
							// 	let newMoveRating = getMoveRating({
							// 		before: currentEval,
							// 		after: stockfishEval,
							// 		side: s.chessboard.getTurn(),
							// 		includeInaccuracy: true,
							// 	});
							// 	if (newMoveRating !== moveRating) {
							// 		toast.error(`Stockfish eval was ${moveRating}, now is ${newMoveRating}`);
							// 	} else {
							// 		toast("Stockfish eval didn't change");
							// 	}
							// });
							set(([s]) => {
								s.chessboard.set((c) => {
									c.moveFeedback.progress = undefined;
									if (moveRating) {
										playSound(SOUNDS.failure);
										if (moveRating === MoveRating.Blunder) {
											c.moveFeedback.result = "blunder";
											s.guessPly(s.ply, "blunders");
										} else if (moveRating === MoveRating.Mistake) {
											c.moveFeedback.result = "mistake";
											s.guessPly(s.ply, "mistakes");
										} else if (moveRating === MoveRating.Inaccuracy) {
											c.moveFeedback.result = "inaccuracy";
											s.guessPly(s.ply, "inaccuracies");
										}
									} else {
										playSound(SOUNDS.success);
										c.moveFeedback.result = "alternative";
										s.validAlternateMovesGuessed.add(move.san);
									}
								});
								if (s.validAlternateMovesGuessed.size >= 3) {
									s.hintStage = "arrow";
								}
								let isMistake =
									moveRating === MoveRating.Mistake || moveRating === MoveRating.Blunder;
								let dismiss = () => {
									set(([s]) => {
										s.chessboard.dismissMoveFeedback({
											square: move.to as Square,
											delay: 500,
											callback: () => {
												set(([s]) => {
													s.previewing = false;
													s.chessboard.backOne({ clear: true });
													if (isMistake) {
														s.chessboard.backOne({ clear: true });
													}
												});
											},
										});
									});
								};
								if (isMistake) {
									let position = s.chessboard.get((s) => s.position).clone();
									devAssert(!!stockfishEval.pv);
									let [refutation] = position.validateMoves([stockfishEval.pv!], { sloppy: true })!;
									devAssert(!!refutation);
									sleep(1000).then(() => {
										set(([s]) => {
											s.chessboard
												.makeMove(refutation, { animate: true, sound: "move" })
												.then(async () => {
													dismiss();
												});
										});
									});
								} else {
									dismiss();
								}
							});
							// const moveRating = getMoveRating;
						})
						.catch((e) => {
							console.error("failed to get stockfish eval", e);
						});
					return true;
				}),
		};
	});
	return initialState;
};
