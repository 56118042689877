import { destructure } from "@solid-primitives/destructure";
import { clsx } from "~/utils/classes";
import { pluralize } from "~/utils/pluralize";
import { c, stylex } from "~/utils/styles";
import { CMText } from "./CMText";

export const ReviewText = (props: {
	date?: string | null;
	hideIcon?: boolean;
	inverse?: boolean;
	overview?: boolean;
	text?: string;
	numDue: number;
	iconColor?: string;
	textColor?: string;
	descriptor?: string;
	class?: string;
	moves?: boolean;
	icon?: string;
}) => {
	const descriptor = () => props.descriptor || "Due";
	const date = () => new Date(props.date || "");
	const now = () => new Date();
	const diff = () => date().getTime() - now().getTime();
	const prefix = () => (props.overview ? `${descriptor()} in` : `${descriptor()} in`);
	const { textColor, iconColor, dueString, bolded } = destructure(() => {
		const bolded = false;
		let dueString = "";
		let iconColor = props.iconColor ?? "text-tertiary";
		let textColor = props.textColor ?? "text-tertiary";
		if (props.text) {
			return {
				iconColor,
				textColor,
				dueString: props.text,
				bolded: true,
			};
		}
		if (!props.date || diff() < 0 || props.numDue > 0) {
			iconColor = props.iconColor ?? (props.inverse ? "text-yellow-30" : "text-yellow-50");
			textColor = "text-secondary";
			if (props.moves) {
				dueString = `${pluralize(props.numDue, "move")}`;
			} else {
				dueString = `${props.numDue.toLocaleString()} ${descriptor()}`;
			}
		} else {
			dueString = `${prefix()} ${getHumanTimeUntil(date())}`;
		}
		return {
			iconColor,
			textColor,
			dueString,
			bolded,
		};
	});
	return (
		<>
			<div style={stylex(c.row, c.alignCenter)} class={clsx(props.class)}>
				<CMText
					class={clsx(
						"text-xs leading-5 tabular-nums",
						textColor(),
						bolded() ? "font-semibold" : "font-semibold",
					)}
				>
					{dueString()}
				</CMText>
				{!props.hideIcon && (
					<i
						style={stylex(c.fontSize(12))}
						class={clsx(props.icon ? props.icon : "fa fa-clock", "pl-2", iconColor())}
					/>
				)}
			</div>
		</>
	);
};

export const getHumanTimeUntil = (date: Date) => {
	const now = new Date();
	const diff = date.getTime() - now.getTime();
	const seconds = diff / 1000;
	const minutes = Math.round(seconds / 60);
	const hours = Math.round(minutes / 60);
	const days = hours / 24;
	if (diff < 0) {
		return "Now";
	}
	if (minutes < 60) {
		return `${pluralize(minutes, "min")}`;
	}
	if (hours < 24) {
		return `${pluralize(hours, "hour")}`;
	}
	return `${pluralize(Math.round(days), "day")}`;
};
